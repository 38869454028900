<template>
  <v-main>
    <v-row justify="center" class="ma-4">
      <v-img
        max-height="400"
        max-width="600"
        :src="
          require('../../../public/images/alex-haney-CAhjZmVk5H4-unsplash.jpg')
        "
      ></v-img>
    </v-row>
    <h1>식당을 통째로 예약하세요</h1>
    <h3 class="ma-4">
      친구들과 파티를 할때나 결혼식 피로연을 할때 자주가는 식당을 통째로
      빌리고싶은 생각 해보신적 있으신가요?
    </h3>
    <h3>
      렌트어식당에선 가능합니다 예약하고 싶은 식당을 선택후 상담신청해보세요🙂
    </h3>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
</style>